import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-0a8069f2"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 1,
  class: "page page-static"
};
const _hoisted_2 = {
  class: "version-wrap"
};
const _hoisted_3 = {
  key: 0,
  class: "template"
};
const _hoisted_4 = {
  key: 1,
  class: "version"
};
const _hoisted_5 = {
  class: "sub-title-box"
};
const _hoisted_6 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_not_found = _resolveComponent("not-found");
  const _component_el_breadcrumb_item = _resolveComponent("el-breadcrumb-item");
  const _component_el_breadcrumb = _resolveComponent("el-breadcrumb");
  const _component_t_layout = _resolveComponent("t-layout");
  return _openBlock(), _createBlock(_component_t_layout, {
    "is-white-bg": true,
    "is-header-padding": true
  }, {
    default: _withCtx(() => [_ctx.isError ? (_openBlock(), _createBlock(_component_not_found, {
      key: 0
    })) : (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_breadcrumb, {
      "separator-class": "el-icon-arrow-right"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_breadcrumb_item, {
        to: {
          path: '/'
        }
      }, {
        default: _withCtx(() => [_createTextVNode("首页")]),
        _: 1
      }), _createVNode(_component_el_breadcrumb_item, null, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.agreeConf.title), 1)]),
        _: 1
      }), _createElementVNode("div", _hoisted_2, [_ctx.agreeConf.templateNo ? (_openBlock(), _createElementBlock("span", _hoisted_3, "范本编号：" + _toDisplayString(_ctx.agreeConf.templateNo), 1)) : _createCommentVNode("", true), _ctx.agreeConf.version ? (_openBlock(), _createElementBlock("span", _hoisted_4, "版本号：" + _toDisplayString(_ctx.agreeConf.version), 1)) : _createCommentVNode("", true)])]),
      _: 1
    }), _createElementVNode("h2", null, _toDisplayString(_ctx.agreeConf.title), 1), _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.agreeConf.publishTime), 1), _createElementVNode("div", {
      class: "content-wrap",
      innerHTML: _ctx.agreeConf.content
    }, null, 8, _hoisted_6)]))]),
    _: 1
  });
}
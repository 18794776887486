import { defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import TLayout from '@/components/Layout/Layout.vue';
import NotFound from '@/components/NotFound/index.vue';
export default defineComponent({
  components: {
    TLayout,
    NotFound
  },
  setup() {
    var _route$params;
    const route = useRoute();
    const lang = ((_route$params = route.params) === null || _route$params === void 0 ? void 0 : _route$params.lang) || 'zh-CN';
    const isError = ref(false);
    const pathConf = {
      service: 'serviceAgreement',
      privacy: 'privacyAgreement',
      order: 'orderAgreement',
      dataprocessing: 'dataProcessingAgreement'
    };
    const agreeConf = ref({});
    const filename = pathConf[route.params.type.toString()];
    if (filename) {
      import( /* webpackChunkName: "Agrt" */`./conf/${filename}`).then(({
        data
      }) => {
        agreeConf.value = data[lang];
      });
    } else {
      isError.value = true;
    }
    return {
      agreeConf,
      isError
    };
  }
});